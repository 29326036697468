import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@angloeastern/react-library';
import BlueWorldMapLoader from '@components/Loading/BlueWorldMapLoader';
import AuthProvider from '@context/Auth';
import { Provider as FeatureTogglesProvider } from '@context/FeatureToggles';
import { IDSAuthProvider } from '@context/IDSAuth';
import App from './app/App';

import 'animate.css';
import '../promise-pollyfil';
import './i18n';
import './index.css';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  const app = (
    <StrictMode>
      <Suspense
        fallback={
          <ThemeProvider>
            <BlueWorldMapLoader />
          </ThemeProvider>
        }
      >
        <Router>
          <FeatureTogglesProvider>
            <AuthProvider>
              <IDSAuthProvider>
                <App />
              </IDSAuthProvider>
            </AuthProvider>
          </FeatureTogglesProvider>
        </Router>
      </Suspense>
    </StrictMode>
  );

  root.render(app);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
