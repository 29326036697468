import { withTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import BlueWorldMapLoader from '@components/Loading/BlueWorldMapLoader';
import { Feature, useFeatureToggles } from '@context/FeatureToggles';
import { useIDSAuth } from '@context/IDSAuth';
import { Provider as ThemeProvider } from '@context/Theme';
import useFreshDesk from '@hooks/useFreshDesk';
import PublicArea from './PublicArea';
import RestrictedArea from './RestrictedArea';

const App = () => {
  const { isEnabled } = useFeatureToggles();
  const oidcAuth = useAuth();
  const idsAuth = useIDSAuth();
  const auth = isEnabled(Feature.IDS) ? idsAuth : oidcAuth;

  const { preFillForm: setupFreshdesk } = useFreshDesk();

  if (auth.isLoading) {
    return (
      <ThemeProvider>
        <BlueWorldMapLoader />
      </ThemeProvider>
    );
  }

  if (auth.error) {
    return <PublicArea />;
  }

  if (auth.isAuthenticated) {
    setupFreshdesk();
    return <RestrictedArea />;
  } else {
    return <PublicArea />;
  }
};

export default withTranslation()(App);
