import { Fragment, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Feature, useFeatureToggles } from '@context/FeatureToggles';
import { useIDSAuth } from '@context/IDSAuth';
import { CONFIG } from '@library/config';
import Home from '@routes/Home';
import { getSupportedLanguages } from '@utils/index';
import Providers from './Providers';
import StyleThemeProvider from './Providers/StyleThemeProvider';
import { SetupPDFJS } from './SetupPDFjs';

const Accounts = lazy(() => import('@routes/Accounts'));
const Certificates = lazy(() => import('@routes/Certificates'));
const Crew = lazy(() => import('@routes/Crew'));
const Documents = lazy(() => import('@routes/Documents'));
const Drawings = lazy(() => import('@routes/Drawings'));
const ErrorPage = lazy(() => import('@routes/ErrorPage'));
const Kpis = lazy(() => import('@routes/Kpis'));
const Location = lazy(() => import('@routes/Location'));
const Login = lazy(() => import('@routes/Login'));
const NotFound = lazy(() => import('@routes/NotFound'));
const Oidc = lazy(() => import('@routes/Oidc'));
const OpexSupportings = lazy(() => import('@routes/OpexSupportings'));
const OurExperts = lazy(() => import('@routes/OurExperts'));
const Reports = lazy(() => import('@routes/Reports'));
const SSOPage = lazy(() => import('@routes/SSOPage'));
const WhatsNew = lazy(() => import('@routes/WhatsNew'));
const WorldMap = lazy(() => import('@routes/WorldMap'));

SetupPDFJS();
const { HOST } = CONFIG;

const RestrictedArea = () => {
  const { handleLogout, user } = useIDSAuth();
  const { isEnabled } = useFeatureToggles();

  // wait BE integration
  if (isEnabled(Feature.IDS)) {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <div
              style={{
                height: '100vh',
                background: 'orange'
              }}
            >
              <h1>LOGGED IN SUCCESSFULLY!! as {user?.fullName}</h1>
              <button
                onClick={() => handleLogout({ postLogoutRedirectUri: HOST })}
              >
                Logout
              </button>
            </div>
          }
        />
      </Routes>
    );
  }

  return (
    <Routes>
      {getSupportedLanguages().map((locale: string) => (
        <Fragment key={locale}>
          <Route
            key={locale}
            path={`/${locale === 'en' ? '' : locale}/error`}
            element={
              <StyleThemeProvider>
                <ErrorPage />
              </StyleThemeProvider>
            }
          />
        </Fragment>
      ))}

      {/* Routes with Providers */}
      <Route
        path="*"
        element={
          <Providers>
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route path="/authSuccess" element={<Oidc />} />
              <Route path="/usercallback" element={<Oidc />} />
              <Route path="/sso" element={<SSOPage />} />
              <Route path="/silent-renew" element={<>Silent Renew</>} />
              {getSupportedLanguages().map((locale: string) => {
                const lang = locale === 'en' ? '' : `/${locale}`;
                return (
                  <Fragment key={locale}>
                    <Route path={`/${locale}`} element={<Home />} />
                    <Route path={`${lang}/login`} element={<Login />} />
                    <Route path={`${lang}/sso`} element={<SSOPage />} />
                    <Route path={`${lang}/location`} element={<Location />} />
                    <Route path={`${lang}/worldmap`} element={<WorldMap />} />
                    <Route
                      path={`${lang}/ourexperts`}
                      element={<OurExperts />}
                    />
                    <Route
                      path={`${lang}/opex-supporting/:vesselAeCode/:transactionId`}
                      element={<OpexSupportings />}
                    />
                    <Route path={`${lang}/whatsnew`} element={<WhatsNew />} />
                    <Route path={`${lang}/crew`} element={<Crew />} />
                    <Route path={`${lang}/documents`} element={<Documents />} />
                    <Route path={`${lang}/accounts`} element={<Accounts />} />
                    <Route
                      path={`${lang}/certificates`}
                      element={<Certificates />}
                    />
                    <Route path={`${lang}/reports`} element={<Reports />} />
                    <Route path={`${lang}/drawings`} element={<Drawings />} />
                    <Route path={`${lang}/kpi`} element={<Kpis />} />
                    <Route path={`${lang}/*`} element={<NotFound />} />
                  </Fragment>
                );
              })}
            </Routes>
          </Providers>
        }
      />
    </Routes>
  );
};

export default RestrictedArea;
