import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import { CONFIG } from '@library/config';
import {
  generateCodeChallenge,
  generateCodeVerifier,
  generateState,
  stateBase64Encode
} from '@utils/pkce';
import { AUTH_STORAGE_KEY, CLIENT_ID, SCOPE } from './constants';
import type { AuthContextType, AuthType } from './types';

const { CODE_VERIFIER, IS_AUTHENTICATED, AUTH_STATE } = AUTH_STORAGE_KEY;
const { IDS_URI, HOST } = CONFIG;
const REDIRECT_URI = `${HOST}/callback`;

const handleAuthorization = (returnTo?: string) => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const state = stateBase64Encode(
    JSON.stringify({
      nonce: generateState(),
      returnTo: returnTo || '/'
    })
  );

  sessionStorage.setItem(CODE_VERIFIER, codeVerifier);
  sessionStorage.setItem(AUTH_STATE, state);

  const params = new URLSearchParams({
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    response_type: 'code',
    scope: SCOPE,
    state,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    response_mode: 'query'
  });

  const authUrl = `${IDS_URI}/connect/authorize/callback?${params.toString()}`;
  window.location.href = authUrl;
};

const handleLogout = (params?: { postLogoutRedirectUri?: string }) => {
  const { postLogoutRedirectUri } = params || {};
  sessionStorage.removeItem(IS_AUTHENTICATED);
  const encodedLogoutUri = encodeURIComponent(postLogoutRedirectUri ?? HOST);
  window.location.href = `${IDS_URI}/account/logout?post_logout_redirect_uri=${encodedLogoutUri}`;
};

const createAuthStore = () => {
  let accessToken = '';
  return {
    setToken: (token: string) => {
      accessToken = token;
      sessionStorage.setItem(IS_AUTHENTICATED, 'true');
    },
    token: accessToken,
    resetToken: () => {
      accessToken = '';
      sessionStorage.removeItem(IS_AUTHENTICATED);
    }
  };
};
const authStore = createAuthStore();

const IDSAuthContext = createContext<AuthContextType | undefined>(undefined);

export const IDSAuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState<AuthType>({
    isAuthenticated: false,
    isLoading: false,
    error: '',
    user: null
  });

  const handleError = (error: Error) => {
    setAuth({
      isAuthenticated: false,
      isLoading: false,
      error: error.message,
      user: null
    });
  };

  const fetchAccessToken = useCallback(
    async (code: string, receivedState: string) => {
      const storedState = sessionStorage.getItem(AUTH_STATE);
      const codeVerifier = sessionStorage.getItem(CODE_VERIFIER);

      if (receivedState !== storedState || !codeVerifier) {
        throw new Error('Invalid params');
      }

      const params = new URLSearchParams({
        code,
        grant_type: 'authorization_code',
        redirect_uri: REDIRECT_URI,
        client_id: CLIENT_ID,
        code_verifier: codeVerifier
      });

      try {
        setAuth({
          isAuthenticated: false,
          isLoading: true,
          error: '',
          user: null
        });
        const response = await fetch(`${IDS_URI}/connect/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: params.toString()
        });

        if (!response.ok) {
          throw new Error('Invalid token');
        }

        const data = await response.json();
        authStore.setToken(data.access_token);
        // uncomment when BE ready
        // const { firstName, lastName, email } = await fetchUserDetails();
        const { firstName, lastName, email } = {
          firstName: 'hey',
          lastName: 'ho',
          email: ''
        };
        setAuth({
          isAuthenticated: true,
          isLoading: false,
          error: '',
          user: {
            email: email,
            fullName: `${firstName} ${lastName}`
          }
        });

        // reset storage
        sessionStorage.removeItem(CODE_VERIFIER);
        sessionStorage.removeItem(AUTH_STATE);
      } catch (error) {
        console.error('@@ Invalid token: ', error);
        authStore.setToken('');
        if (error instanceof Error) {
          handleError(error);
        }
      }
    },
    [authStore, auth]
  );

  const value = useMemo(
    () => ({
      isAuthenticated: auth.isAuthenticated,
      isLoading: auth.isLoading,
      error: auth.error,
      user: auth.user,
      accessToken: authStore.token,
      handleAuthorization,
      handleCallback: fetchAccessToken,
      handleLogout,
      handleError
    }),
    [auth.isAuthenticated, auth.isLoading, auth.error, authStore.token]
  );

  return (
    <IDSAuthContext.Provider value={value}>{children}</IDSAuthContext.Provider>
  );
};

export const useIDSAuth = () => {
  const context = useContext(IDSAuthContext);

  if (!context) {
    throw new Error('useIDSAuth must wrapped with IDSAuthProvider');
  }

  return context;
};
