import crypto from 'crypto-js';

export const generateCodeVerifier = () => {
  const random = crypto.lib.WordArray.random(32);
  return base64URLEncode(random);
};

export const generateCodeChallenge = (verifier: string) => {
  const hash = crypto.SHA256(verifier);
  return base64URLEncode(hash);
};

const base64URLEncode = (str: crypto.lib.WordArray) => {
  return str
    .toString(crypto.enc.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

export const generateState = () => {
  return crypto.lib.WordArray.random(16).toString();
};

export const stateBase64Decode = (str: string): string => {
  try {
    return decodeURIComponent(
      Array.prototype.map
        .call(
          window.atob(str),
          (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        )
        .join('')
    );
  } catch (e) {
    console.warn('Failed to decode base64 string:', e);
    return '';
  }
};

export const stateBase64Encode = (str: string): string => {
  return window.btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );
};
