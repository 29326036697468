import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useError } from '@hooks/useError';
import { errorTracker } from '@utils/errorTracker';
import { fetchFeatures } from './api';

export const Context = createContext({});

export enum Feature {
  ANIMATE_VESSEL = 'ANIMATE_VESSEL',
  CREW_TIMELINE_VIEW = 'CREW_TIMELINE_VIEW',
  NOTIFICATIONS = 'NOTIFICATIONS',
  LEVEL4_COA = 'LEVEL4_COA',
  WEBSOCKET = 'WEBSOCKET',
  NOTIFICATIONS_SETTINGS = 'NOTIFICATIONS_SETTINGS',
  IDS = 'IDS'
}

export const Provider = ({ children }: { children: ReactNode }) => {
  const [features, setFeatures] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      try {
        const data = await fetchFeatures(signal);
        setFeatures(data);
      } catch (e: any) {
        errorTracker(e);
        setError(true);
      }
    }

    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  const state = useMemo(() => ({ ...features, error }), [features, error]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useFeatureToggles = () => {
  const { error, ...features } = useContext<any>(Context);
  const { setError } = useError();

  useEffect(() => {
    if (error) {
      setError(true);
    }
  }, [error]);

  const isEnabled = (varName: string) => {
    return features[varName] || false;
  };

  return {
    features,
    error,
    isEnabled
  };
};
