import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';

import { useAuthUser } from '@context/AuthUser';
import { Feature, useFeatureToggles } from '@context/FeatureToggles';

const useFreshDesk = () => {
  const { isEnabled } = useFeatureToggles();
  const auth = useAuth();
  const idsAuth = useAuthUser();
  const { profile } = isEnabled(Feature.IDS) ? idsAuth : auth;
  const w = window as any;

  const fillForm = (name: string, email: string) => {
    w?.FreshworksWidget('identify', 'ticketForm', {
      name,
      email
    });
  };

  const hideForm = () => w?.FreshworksWidget('hide');
  const showForm = () => w?.FreshworksWidget('open');
  const hideLauncher = () => w?.FreshworksWidget('hide', 'launcher');
  const showLauncher = () => w?.FreshworksWidget('show', 'launcher');

  const preFillForm = useCallback(() => {
    if (!profile) return;
    fillForm(profile.fullName, profile.email);
  }, [profile]);

  return {
    fillForm,
    hideForm,
    showForm,
    preFillForm,
    hideLauncher,
    showLauncher
  };
};

export default useFreshDesk;
