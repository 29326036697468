import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { memoize } from '@angloeastern/react-library';
import { Features } from '@components/types';
import { Feature, useFeatureToggles } from '@context/FeatureToggles';
import { useIDSAuth } from '@context/IDSAuth';
import { useProfile } from '@context/Profile';
import type { Feature as FeatureType, UserData } from '@context/Profile/types';
import { AccessTypeID } from '@context/Profile/types';
import { errorTracker } from '@utils/errorTracker';

export const Context = createContext({});

type PagePermit = {
  id: string;
  permit: Features;
};

export const pages: PagePermit[] = [
  {
    id: 'location',
    permit: Features.LOCATION_NAVIGATION
  },
  {
    id: 'worldmap',
    permit: Features.LOCATION_NAVIGATION
  },
  {
    id: 'crew',
    permit: Features.CREWS
  },
  {
    id: 'accounts',
    permit: Features.FINANCIAL
  },
  {
    id: 'opex-supporting',
    permit: Features.FINANCIAL
  },
  {
    id: 'kpi',
    permit: Features.KPI
  },
  {
    id: 'certificates',
    permit: Features.CERTIFICATES
  },
  {
    id: 'reports',
    permit: Features.REPORTS
  },
  {
    id: 'documents',
    permit: Features.DOCUMENTS
  },
  {
    id: 'drawings',
    permit: Features.DRAWINGS
  },
  {
    id: 'resources',
    permit: Features.AERESOURCES
  }
];

export const Provider = ({ children }: { children: ReactNode }) => {
  const { isEnabled } = useFeatureToggles();
  const { signoutRedirect, ...auth } = useAuth();
  const idsAuth = useIDSAuth();
  const idsEnabled = isEnabled(Feature.IDS);
  const { isLoading, isAuthenticated } = idsEnabled ? idsAuth : auth;

  const {
    state: { user: profileUser, error, unauthorized },
    fetchData
  } = useProfile();
  const [state, setState] = useState<any>({ error: false });
  const [fetching, setFetching] = useState(false);

  const getFeature = memoize((userdata: UserData, featureName: string) => {
    return userdata.permissions?.featureList.find(
      (feat: FeatureType) =>
        feat.beCode.trim().toUpperCase() === featureName.trim().toUpperCase()
    );
  });

  const isFeaturePermitted = memoize(
    (featureName: string, accessTypeIds: AccessTypeID[]) => {
      if (
        !profileUser?.permissions?.featureList ||
        !featureName ||
        accessTypeIds.length === 0
      )
        return false;

      const feature = getFeature(profileUser, featureName);
      return feature ? accessTypeIds.includes(feature.accessTypeId) : false;
    }
  );

  const isSourceAccessPermitted = memoize(
    (userdata: UserData, source: string) => {
      source = source.trim().toUpperCase();
      userdata.permissions = userdata.permissions || {
        featureList: [],
        sourceSystems: []
      };
      const permit = userdata.permissions.sourceSystems.find((src: string) => {
        const sourceName = src.trim().toUpperCase();
        return sourceName === source;
      });

      return permit !== undefined;
    }
  );

  const hasPermission = (feature: string) => hasReadPermission(feature);

  const hasReadPermission = (feature: string) =>
    isFeaturePermitted(feature, [AccessTypeID.READ, AccessTypeID.READ_WRITE]);

  const showSystems = () => {
    const [firstSourceSystem] = profileUser?.permissions?.sourceSystems || [];
    return firstSourceSystem !== '';
  };

  const hasReadWritePermission = (feature: string) =>
    isFeaturePermitted(feature, [AccessTypeID.READ_WRITE]);

  const hasAccess = (source: string) => {
    source = source.trim().toLocaleLowerCase();

    if (profileUser?.permissions) {
      return isSourceAccessPermitted(profileUser, source);
    }

    return false;
  };

  const getLandingPage = () => {
    for (const page of pages) {
      if (hasPermission(page.permit)) {
        return page;
      }
    }
    return null;
  };

  // log out in case of unauthorized
  useEffect(() => {
    if (unauthorized) {
      idsEnabled ? signoutRedirect() : idsAuth.handleLogout();
    }
  }, [unauthorized]);

  useEffect(() => {
    if (state.error !== error) {
      setState({
        ...state,
        error
      });
    }
  }, [error]);

  useEffect(() => {
    if (!idsAuth.user && !auth.user && !isLoading && !isAuthenticated) {
      window.location.href = '/';
    }
  }, [idsAuth.user, auth.user, isLoading, isAuthenticated]);

  useEffect(() => {
    if (auth.user && isAuthenticated && !state.token) {
      const { user } = auth;
      setState({
        ...state,
        isOffice365: user.scope?.includes('office365'),
        profile: JSON.parse(user.profile.sub),
        token: user.access_token
      });
    } else if (idsAuth.user && isAuthenticated && !state.token) {
      setState({
        ...state,
        isOffice365: false,
        profile: idsAuth.user,
        token: idsAuth.accessToken
      });
    }
  }, [idsAuth.user, auth.user, isAuthenticated, state]);

  useEffect(() => {
    if (state.profile && !state.roleName) {
      setState({
        ...state,
        initial: state.profile.fullName[0],
        roleName: state.profile.roleName
      });
    }
  }, [state]);

  useEffect(() => {
    async function fetchProfileData() {
      try {
        setFetching(true);
        await fetchData();
        setFetching(false);
      } catch (e: any) {
        errorTracker(e);
        window.location.href = '/error';
      }
    }

    if (state.profile && !profileUser && !fetching) {
      fetchProfileData();
    } else if (
      profileUser?.profile &&
      profileUser?.permissions &&
      profileUser?.userDetails &&
      !state.permissions
    ) {
      setState({
        ...state,
        permissions: profileUser.permissions,
        vesselCodes: profileUser.userDetails.vesselAeCode,
        orgAeCodes: profileUser.userDetails.organizationAeCode,
        userLanguage: profileUser.profile.language,
        landingPage: getLandingPage()
      });
    }
  }, [profileUser, state, fetching]);

  const ctxState = useMemo(
    () => ({
      ...state,
      isLoading,
      isAuthenticated,
      hasPermission,
      hasReadPermission,
      hasReadWritePermission,
      hasAccess,
      showSystems: showSystems()
    }),
    [state, isLoading, isAuthenticated]
  );

  return <Context.Provider value={ctxState}>{children}</Context.Provider>;
};

export const useAuthUser = () => useContext<any>(Context);
